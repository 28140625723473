








import { defineComponent, PropType, ref, useFetch } from '@nuxtjs/composition-api';
import type { ProductWithCommonProductCardProps } from '~/modules/catalog/category/components/views/useProductsWithCommonCardProps';
import { merge } from "lodash-es";

export default defineComponent({
  name: 'FortytwoCategoryProductDimension',
  props: {
    product: {
      type: Object as PropType<ProductWithCommonProductCardProps>,
      required: true,
    },
  },
  setup(props) {
    const dimension = ref('');    
    const dimensions = ref({
      'Width (cm): ': props.product.width ?? 0, 
      'Height (cm): ': props.product.height ?? 0, 
      'Depth (cm): ': props.product.depth ?? 0, 
      'Length (cm): ': props.product.length ?? 0, 
      'Diameter (cm): ': props.product.diameter ?? 0
    });

    useFetch(() => {
      for(const [dimensionLabel, dimensionValue] of Object.entries(dimensions.value)) {
        if(dimensionValue) {
          dimensions.value =  merge({}, dimensions.value, { [dimensionLabel] : dimensionLabel + dimensionValue });
        } else {
          Reflect.deleteProperty(dimensions.value, dimensionLabel);
        }
      }
      dimension.value = Object.values(dimensions.value).join(', ');
    });

    return {
      dimension,
    };
  },
});
