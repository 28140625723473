






import {
  defineComponent,
} from '@nuxtjs/composition-api';

export default defineComponent({
  name: 'FortytwoCategoryProductStockStatus',
  props: {
    product: {
      type: Object,
      required: true,
    },
  },
});
