


















import { defineComponent, PropType, onBeforeMount, ref, computed, } from '@nuxtjs/composition-api';
import { Product } from '~/modules/catalog/product/types';
import { useCustomerStore } from '~/modules/customer/stores/customer';

export default defineComponent({
  name: 'FortyTwoProductLabel',
  props: {
    product: {
      type: [Object, null] as PropType<Product>,
      default: null,
    },
    imageSize: {
        type: Object, 
    }
  },
  setup(props) {
    const productLabel = ref([]);
    const fetchProductLabel= () => {
        if (props.product.mp_label_data){
            for(const label of props.product.mp_label_data) {
                const labelPosition = JSON.parse(label.label_position);
                label.label_style_width = labelPosition.label.width * 100 / props.imageSize.width;
                label.label_style_height = labelPosition.label.height * 100 / props.imageSize.height;
                label.label_style_percentTop = ((props.imageSize.height - labelPosition.label.height) * labelPosition.label.percentTop / 100) / props.imageSize.height * 100;
                label.label_style_percentLeft = ((props.imageSize.width - labelPosition.label.width) * labelPosition.label.percentLeft / 100) / props.imageSize.width * 100;
                label.label_custom_css = label.label_css.replace('design-labels',`design-labels-${label.rule_id}`)
                .replace('design-label-image',`design-label-image-${label.rule_id}`)
                .replace('design-label-text',`design-label-text-${label.rule_id}`);
                productLabel.value.push(label); 
            }
        } 
    };
    const customerStore = useCustomerStore();
    const checkCustGroupId = computed(()=>customerStore.group_id ?? 0);

    const validCustomerGroupId = (group_ids) =>{
        if(group_ids) {
            return Array.from(group_ids.split(','),Number).includes(checkCustGroupId.value);
        }
        return false;
    };

    onBeforeMount(async () => {
      await fetchProductLabel();
    });

    return {
        productLabel,
        validCustomerGroupId,
    }
  }
});
