




























































import {
  ref,
  defineComponent,
  watchEffect,
  computed,
} from '@nuxtjs/composition-api';
import {
  SfImage,
  SfButton,
  SfIcon,
} from '@storefront-ui/vue';
import { useImage } from '~/composables';
import ProductLabel from '~/modules/fortytwo/product/components/ProductLabel.vue';

export default defineComponent({
  name: 'FortytwoCategoryProductImage',
  components: {
    SfImage,
    SfButton,
    SfIcon,
    ProductLabel,
  },
  props: {
    product: {
      type: Object,
      required: true,
    },
    itmImage: {
      type: String,
    },
    itmUID: {
      type: String,
    },
  },
  setup(props, { emit }) {
    const { imageSizes: { productCard: imageSize } } = useImage();

    const placeholder = ref('/custom/fortytwo-loading-icon.gif');

    const itemImage = ref(props.product.commonProps.image);

    watchEffect( () => {
      if (props.product.uid === props.itmUID) {
        itemImage.value = props.itmImage;
      }
    });

    const ariaLabel = computed(() => props.product.commonProps.isInWishlist ? "Remove from wishlist" : "Add to wishlist");

    const currentWishlistIcon = computed(() => props.product.commonProps.isInWishlist ? 
    props.product.commonProps.isInWishlistIcon : props.product.commonProps.wishlistIcon);

    const wishlistIconClasses = computed(() => {
      const defaultClass = "sf-button--pure sf-product-card__wishlist-icon";
      return `${defaultClass} ${props.product.commonProps.isInWishlist ? "on-wishlist" : ""}`;
    });

    const toggleIsInWishlist = () => {
      if (!props.product.commonProps.isAuthenticated) {
        emit("click:wishlist-login", !props.product.commonProps.isInWishlist);
      } else {
        emit("click:wishlist", !props.product.commonProps.isInWishlist);
      }
    };

    return {
      imageSize,
      itemImage,
      placeholder,
      ariaLabel,
      currentWishlistIcon,
      wishlistIconClasses,
      toggleIsInWishlist,
    };
  },
});
