<template><div id="GA4ViewItemList"></div></template>
<script>
import { ref,onMounted } from "@nuxtjs/composition-api";
import { useUser } from '~/modules/customer/composables/useUser';
import { sha256 } from 'js-sha256';

export default {
  name: "GA4ViewItemList",
  props: {
    product: {
    type: Array,
    required: true,
    },
    activeCategory: String,
  },
  methods: {
    track(data) {
      if (data.length == this.product.length){
        if(this?.user?.email){
          const userId = sha256.hex(this.user.email);
          this.$gtag.event("userIdSet", {
            'event_label': "userIdSet",
            'currency': this.product[0]?.price_range.maximum_price?.final_price.currency ?? '',
            'items': data,
            'user_id': userId
          });
        }
        
        this.$gtag.event("view_item_list", {
          'event_label': "view_item_list",
          'currency': this.product[0]?.price_range.maximum_price?.final_price.currency ?? '',
          'items': data
        });
      }
    }
  },
  mounted(){
    if(this.productInfoGA4){
      this.track(this.productInfoGA4);
    }
  },
  setup(props) {
    const { user, load: loadUser } = useUser();
    const productInfoGA4 = ref([]);
    
    for (const productData of props.product) {
      productInfoGA4.value.push({
          item_id: productData?.sku ?? "",
          item_name: productData?.name ?? "",
          price: productData?.price_range.maximum_price?.final_price.value ?? 0,
          item_category: productData?.product_category ?? "",
          item_brand: productData?.product_brand_label ?? "",
          index: productInfoGA4.value.length + 1,
        });
    }

    onMounted(async () => {
      if (user.value === null) {
        await loadUser();
      }
    });

    return{
      productInfoGA4,
      user,
    }

  }
};
</script>

  