






import { defineComponent, PropType } from "@nuxtjs/composition-api";
import ProductPrice from "~/modules/fortytwo/product/components/ProductPrice.vue";
import type { ProductWithCommonProductCardProps } from "~/modules/catalog/category/components/views/useProductsWithCommonCardProps";

export default defineComponent({
  name: "FortytwoCategoryProductPrice",
  components: {
    ProductPrice,
  },
  props: {
    product: {
      type: Object as PropType<ProductWithCommonProductCardProps>,
      required: true,
    },
  },
});
