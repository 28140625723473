






































import {
  ref,
  computed,
  defineComponent,
  onMounted,
  onBeforeUnmount,
} from '@nuxtjs/composition-api';
import {
  SfRating,
  SfColor,
} from '@storefront-ui/vue';

import { merge } from 'lodash-es';
import { useCategoryproductoptionStore } from '~/modules/fortytwo/category/stores/categoryproductoption';

export default defineComponent({
  name: 'FortytwoCategoryProductSwatch',
  components: {
    SfRating,
    SfColor,
  },
  props: {
    product: {
      type: Object,
      required: true,
    },
  },
  setup(props, { emit }) {
    const productConfiguration = ref({});

    //to get the swatch value based on color uid.
    const getCustomProductSwatchData = (colorUid: string) =>{
      for (const swatchdata in props.product.category_custom_swatch?.swatch_data) {
        if(colorUid == props.product.category_custom_swatch?.swatch_data[swatchdata]?.uid){
          return props.product.category_custom_swatch?.swatch_data[swatchdata]?.swatch_value;
        }
      }
    };

    // Product Configurable Options
    const CategoryProductOption = useCategoryproductoptionStore();
    const configurableOptions = computed(() => props.product.configurable_options ?? []);
    const updateProductConfiguration = (label: string, value: string) => {
      if (productConfiguration.value[label] === value) return;
      productConfiguration.value =  merge({}, productConfiguration.value, { [label] : value });
      // Update Product Image and Product Details based on the configurable option selected
      fetchProductExtendedData(value);
      // Set Pinia State
      CategoryProductOption.$patch((state)=>{ state.result = productConfiguration.value });
      // pass current 'configurable_options' and 'productConfiguration' variable to categoryProductGrid
      emit('passProductDetail', {productConfigOption:props.product.configurable_options, productConfigurationSelected:productConfiguration.value});
    };
    const fetchProductExtendedData = async (selectedColorUid) => {
      let selectedImageUrl = "";
      if (Array.isArray(props.product.category_custom_swatch?.swatch_data)) {
        selectedImageUrl = props.product?.category_custom_swatch?.swatch_data.filter((curr)=> curr.uid == selectedColorUid)?.[0]?.product_image;
      }
      const itemImage= computed(() => selectedImageUrl || props.product.commonProps.image);
      // Pass New Product Image Path to Parent
      emit('fetchProductImage', {productImage:itemImage, productUID:props.product.uid});
    };

    //check swatch
    const showColorSwatch = (option) =>{    
      if(getCustomProductSwatchData(option?.[0]?.uid)){
        return true;
      }
      return false;
    };

    // Get max color qty based on screen size
    const maxColorSize = ref(3);
    const updateScreenSize = () => maxColorSize.value = window.screen.width < 1024 || window.innerWidth < 1024 ? 3 : 5;
    onMounted(() => {
      updateScreenSize();
      window.addEventListener("resize", updateScreenSize);
    });

    onBeforeUnmount(()=>{
      window.removeEventListener("resize", updateScreenSize);
    });

    return {
      configurableOptions,
      productConfiguration,
      updateProductConfiguration,
      getCustomProductSwatchData,
      maxColorSize,
      showColorSwatch,
    };

  }
});
