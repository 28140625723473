










































import { defineComponent, PropType, toRefs, ref } from "@nuxtjs/composition-api";
import FtProductCard from "~/components/customSFUI/vue/src/components/organisms/FtProductCard/FtProductCard.vue";
import { useImage } from "~/composables";
import type { Product } from "~/modules/catalog/product/types";

import SkeletonLoader from "~/components/SkeletonLoader/index.vue";
import { useProductsWithCommonProductCardProps } from "./useProductsWithCommonCardProps";
import GA4ViewItemList from '~/modules/fortytwo/googleanalytics4/components/GA4ViewItemList.vue';
import { useCart } from '~/modules/checkout/composables/useCart';
export default defineComponent({
  components: {
    FtProductCard,
    SkeletonLoader,
    GA4ViewItemList,
  },
  props: {
    products: {
      type: Array as PropType<Product[]>,
      required: true,
    },
    pricesLoaded: Boolean,
    loading: Boolean,
    activeCategory: String,
  },
  emits: ["click:wishlist", "click:add-to-cart", "click:wishlist-login", "click:add-to-cart-ga4", "click:select-item-ga4"],
  setup(props, {emit}) {
    const {
      imageSizes: { productCard: imageSize },
    } = useImage();
    const { products } = toRefs(props);
    const { productsWithCommonProductCardProps } =
      useProductsWithCommonProductCardProps(products);
    // Get current product image path and product uid
    const itmImage = ref("");
    const itmUID = ref("");
    const updImage = ({productImage, productUID}) => { 
      itmImage.value = productImage.value;
      itmUID.value = productUID;
    }

    // Get current product 'configurable_options' and 'productConfiguration' variable
    const itmConfigurationSelected = ref("");
    const canAddConfigItmToCart = ref(false);
    const getProductDtl = ({productConfigOption, productConfigurationSelected}) =>{
      itmConfigurationSelected.value = productConfigurationSelected;
      // Chek is it the config product can add to cart or not
      canAddConfigItmToCart.value = (productConfigOption.length === Object.keys(productConfigurationSelected).length) ? true : false;
    }
    const { cart } = useCart();
    const activeCategoryName = props.activeCategory; 
    const fetchCustomProductInfo = async (productData, eventName) => {
      if(eventName == "select-item"){
        emit('click:select-item-ga4', { cateProductDetails:productData});
      }else{
        emit('click:add-to-cart-ga4', { cateProductDetails:productData});
      }
    };
    
    return {
      imageSize,
      productsWithCommonProductCardProps,
      updImage,
      itmImage,
      itmUID,
      getProductDtl,
      itmConfigurationSelected,
      canAddConfigItmToCart,
      cart,
      fetchCustomProductInfo,
      activeCategoryName,
    };
  },
});
