























import {
  defineComponent,
  computed,
} from '@nuxtjs/composition-api';
import moment from 'moment';

export default defineComponent({
  name: 'FortytwoCategoryProductLeadTime',
  props: {
    product: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const leadDate = moment().add(props.product.calculated_lead_time, 'd').format("ddd, D MMM 'YY");
    const leadTime = computed(() => props.product.calculated_lead_time >= 0 ? props.product.calculated_lead_time : -1);

    return {
      leadDate,
      leadTime,
    };
  },
});
